export const findElements = (list = {}, request) => {
    if (!request.length) return ''

    if(typeof request === 'string') {
       return findElementByName(request, list)
    }

    if(Array.isArray(request)) {
        if (!Object.keys(list).length) return {};

        return request.reduce((acc, el) => {
            acc[el] = findElementByName(el, list)
            return acc
        }, [])
    }
    return []
};

let findElementByName = (name, list) => {
    const elReplaceGaps = name.trim()
                              .toLowerCase()
                              .replace(/\s/g, '-');

    const elDeleteSymbols = name.trim()
                                .toLowerCase()
                                .replace(/[^a-zа-яё]/gi, '');

    return  list[elReplaceGaps] || list[elDeleteSymbols] || list['default']
}
